export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const LOGOUT_USER = "LOGOUT_USER";

export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

// export const SCHEDULE_LAB_BEGIN = "SCHEDULE_LAB_BEGIN";
// export const SCHEDULE_LAB_SUCCESS = "SCHEDULE_LAB_SUCCESS";
// export const SCHEDULE_LAB_ERROR = "SCHEDULE_LAB_ERROR";

export const SHOW_STATS_BEGIN = "SHOW_STATS_BEGIN";
export const SHOW_STATS_SUCCESS = "SHOW_STATS_SUCCESS";

export const HANDLE_CHANGE = "HANDLE_CHANGE";
