import React from 'react';
import {
  ScheduleComponent,
  Week,
  Inject,
  ViewsDirective,
  ViewDirective,
} from '@syncfusion/ej2-react-schedule';

const NewSchedular = () => {
  // Fetch all taken appointments from the database

  //
  const data = [
    {
      Id: 2,
      Subject: 'Meeting with Mugume',
      StartTime: new Date(2023, 10, 11, 10, 0),
      EndTime: new Date(2023, 10, 11, 12, 30),
      IsAllDay: false,
      //   Status: 'Completed',
      Priority: 'High',
    },
  ];
  const fieldsData = {
    id: 'Id',
    subject: { name: 'Subject' },
    isAllDay: { name: 'IsAllDay' },
    startTime: { name: 'StartTime' },
    endTime: { name: 'EndTime' },
  };
  const eventSettings = { dataSource: data, fields: fieldsData };

  const date = new Date();
  const yesterday = new Date(date.getTime());
  yesterday.setDate(date.getDate() - 1);
  let maxdate = new Date(date.getTime());
  maxdate.setDate(date.getDate() + 7);

  return (
    <ScheduleComponent
      height='550px'
      currentView='Week'
      minDate={yesterday}
      maxDate={maxdate}
      allowMultiCellSelection={false}
      eventSettings={eventSettings}
      onChange={(e) => console.log('onChange', e)}
      onClick={(e) => console.log('onClick', e)}
      eventClick={(e) => console.log('eventClick', e)}
    >
      <ViewsDirective>
        <ViewDirective option='Week' />
      </ViewsDirective>
      <Inject services={[Week]} />
    </ScheduleComponent>
  );
};

export default NewSchedular;
