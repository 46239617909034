import Wrapper from '../../assets/wrappers/DashboardFormPage';
import LabsListFunctional from '../../components/labs/LabsListFunctional';

const Labs = () => {
  return (
    <Wrapper>
      <div>
        <h3>Lab Catalog</h3>
        <p>
          Navigate through the available labs and request for access to any that
          interests you. On evaluation of your request, you will receive
          communication from our team on the next steps
        </p>
        <LabsListFunctional />
      </div>
    </Wrapper>
  );
};
export default Labs;
