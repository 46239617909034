import React from 'react';

const Button = ({ children, ...props }) => {
  return (
    <button
      {...props}
      className={`btn ${props?.variant}`}
      disabled={props.disabled}
    >
      {children}
    </button>
  );
};

export default Button;
