import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const ShowHidePassword = ({
  type,
  name,
  value,
  handleChange,
  labelText,
  disabled,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className='form-row'>
      <label htmlFor={name} className='form-label'>
        {labelText || name}
      </label>
      <i className='eyeIcon' onClick={toggleVisibility}>
        {isVisible ? <BsEye /> : <BsEyeSlash />}
      </i>
      <input
        type={!isVisible ? "password" : "text"}
        value={value}
        name={name}
        onChange={handleChange}
        className='form-input'
      />
    </div>
  );
};
export default ShowHidePassword;
