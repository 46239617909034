//AUTH ROUTES
export const loginRoute = () => ({
  url: `/auth/login`,
});
export const signupRoute = () => ({
  url: `/auth/register`,
});
export const profileUpdateRoute = (userId) => ({
  url: `/users/${userId}`,
});
export const getPartnersRoute = () => ({
  url: `/partners`,
});

export const forgotPasswordRoute = () => ({
  url: `/auth/forgot-password`,
});
export const passwordResetRoute = () => ({
  url: `/auth/reset-password`,
});

export const requestAccountVerificationRoute = () => ({
  url: `/users/requestAccountVerification`,
});
export const accountVerificationRoute = () => ({
  url: `/auth/verify-email`,
});
export const getStats = () => ({
  url: `/lab-access/stats`,
});
