import React, { useEffect } from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Page,
  Inject,
  Search,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import '../../assets/sf/grid.css';
import Button from '../Button';
import { axios } from '../../utils/axios';
import { toast } from 'react-toastify';
import { showError } from '../../utils/showError';
import { useState } from 'react';
import Loading from '../Loading';

const toolbarOptions = ['Search'];
const pageSettings = { pageSize: 10 };

const LabsListFunctional = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [labs, setLabs] = useState([]);

  const getDataSources = async () => {
    setLoading(true);
    const { data } = await axios.get(`/labs`);
    const myRequestsRes = await axios.get(`/client/my-requests`);
    const myLabsRes = await axios.get(`/client/my-access`);
    const myRequests = myRequestsRes.data;
    const myLabs = myLabsRes.data;

    // modify data to add status as granted if in myLabs or pending if in myRequests
    const result = data.map((lab, idx) => {
      let status = 'Request';
      const found = myLabs.find((myLab) => myLab.lab.id === lab.id);
      const foundRequest = myRequests.find(
        (myRequest) => myRequest.lab.id === lab.id
      );

      if (found) {
        status = 'Granted';
      } else if (foundRequest) {
        status = 'Pending';
      } else {
        status = 'Request';
      }

      return {
        id: lab?.id,
        labNo: idx + 1,
        labName: lab?.name,
        labCode: lab?.code,
        timeInterval: `${lab?.timeMinutes} minutes`,
        status,
      };
    });
    setLoading(false);
    setLabs(result);
  };

  useEffect(() => {
    getDataSources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleLabRequest = async (lab) => {
    if (lab.status === 'Granted')
      return toast.info('You already have access to this lab');
    if (lab.status === 'Pending')
      return toast.info('You already have a pending request for this lab');
    const id = lab.id;
    try {
      setIsLoading(true);
      await axios.post(`/lab-requests`, { lab: id });
      setIsLoading(false);
      setRefresh(Math.random());
      toast.success('Lab request sent successfully');
    } catch (error) {
      setIsLoading(false);
      toast.error(showError(error));
    }
  };

  const gridTemplate = (props) => {
    return (
      <div className='text-danger'>
        <Button
          onClick={() => handleLabRequest(props)}
          disabled={
            isLoading ||
            props.status === 'Granted' ||
            props.status === 'Pending'
          }
          variant={props.status}
        >
          {isLoading ? 'Requesting...' : props.status}
        </Button>
      </div>
    );
  };
  return (
    <div className='control-pane'>
      <div className='control-section'>
        {loading && labs.length < 1 ? (
          <Loading center={true} />
        ) : (
          <GridComponent
            dataSource={labs}
            pageSettings={pageSettings}
            allowPaging={true}
            toolbar={toolbarOptions}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText='No.'
                width='20'
                field='labNo'
                textAlign='Right'
              />
              <ColumnDirective
                field='labName'
                headerText='Lab Name'
                width='100'
              />
              <ColumnDirective
                field='labCode'
                headerText='Lab Code'
                width='50'
              />
              <ColumnDirective
                field='timeInterval'
                headerText='Time Interval'
                width='70'
              />

              <ColumnDirective
                headerText='Request Access'
                width='60'
                template={gridTemplate}
              />
            </ColumnsDirective>
            <Inject services={[Page, Search, Toolbar]} />
          </GridComponent>
        )}
      </div>
    </div>
  );
};

export default LabsListFunctional;
