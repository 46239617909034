import Wrapper from "../../assets/wrappers/DashboardFormPage";

import {
  Scheduler,
} from "../../components";

const Schedules = () => {
  return(
  <Wrapper>
    <Scheduler/>
  </Wrapper>
  )
};
export default Schedules;
